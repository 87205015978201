export default (orbitObjectId, orbitalPathId) => ( 
{
    ellipse: null,
    circle: null,
    ellipseTotalLength: null,
    newPoint: null,
    targetPoint: null,
    currentPoint: 0.5,
    resetTimeout: null,
    init() {
        this.ellipse = document.getElementById(orbitalPathId);
        this.circle = document.getElementById(orbitObjectId);
        this.ellipseTotalLength = this.ellipse.getTotalLength();
        this.setInitialPosition();
    },
    setInitialPosition() {
        this.newPoint = this.ellipse.getPointAtLength(
            this.ellipseTotalLength * this.currentPoint
        );
        this.circle.setAttributeNS(null, "cx", Number(this.newPoint.x));
        this.circle.setAttributeNS(null, "cy", Number(this.newPoint.y));
    },
    setPosition(point) {
        clearTimeout(this.resetTimeout);
        this.targetPoint = point;
        this.animateTo(this.targetPoint, this.currentPoint);
    },
    resetCircle() {
        this.resetTimeout = setTimeout(() => {
            this.targetPoint = 0.5;
            this.animateTo(this.targetPoint, this.currentPoint);
        }, 500);
    },
    animateTo(target, current) {
        if (Math.abs(target - current) < 0.001) {
            this.currentPoint = target;
            return;
        }

        if (current > target) {
            current -= 0.001;
        } else if (current < target) {
            current += 0.001;
        }

        this.newPoint = this.ellipse.getPointAtLength(
            this.ellipseTotalLength * Number.parseFloat(current)
        );
        this.circle.setAttributeNS(null, "cx", Number.parseInt(this.newPoint.x));
        this.circle.setAttributeNS(null, "cy", Number.parseInt(this.newPoint.y));
        this.currentPoint = current;

        setTimeout(() => {
            this.animateTo(target, current);
        }, 5);
    },
});
